import { Injectable } from '@angular/core';
import { NotificationService } from '../notification/notification.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlingService {
  constructor(
    private _notificationService: NotificationService,
    private _router: Router,
  ) {}

  handleError(error: HttpErrorResponse, duration?: number): void {
    console.info('Request Details:', {
      url: error.url,
      headers: error.headers,
      error: error,
    });
    let errorMessage = error.error
      ? error.error || 'An unknown error occurred!'
      : 'An unknown error occurred!';
    let redirectToLogin: boolean = false;
    if (error.error instanceof ErrorEvent) {
      // Client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side error
      switch (error.status) {
        case 401:
          errorMessage = error.error || 'Unauthorized request. Please log in.';
          break;
        case 403:
          errorMessage = error.error || 'Access denied.';
          redirectToLogin = true;
          break;
        case 404:
          errorMessage = 'The requested resource was not found.';
          break;
        case 500:
          errorMessage = 'Internal server error. Please try again later.';
          break;
        default:
          errorMessage = error.error || 'Something went wrong!';
          break;
      }
    }
    console.error(`Error Status: ${error.status}\nMessage: ${errorMessage}`);
    this._notificationService.displayError(errorMessage, duration);
    // if (redirectToLogin) {
    //   // Redirect to login page
    //   this._router.navigate(['/']);
    // }
  }
}
