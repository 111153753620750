import { Injectable, computed, inject, signal } from '@angular/core';
import { ApiService } from '../api/api.service';
import { Observable, Subject } from 'rxjs';
import { AccessLevel } from 'app/core/enums';
import { ErrorHandlingService } from '../error-handling/error-handling.service';
import { environment } from 'environments/environment';
import { Region } from 'app/core/models/region.model';
import { CurrentUser } from 'app/core/models/current-user.model';
import { AuditLog } from 'app/core/models/auditlog.model';
import { CompCommunicatorReport } from 'app/core/models/compcommunicatorreport.model';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private _apiService = inject(ApiService);
  private _errorHandlingService = inject(ErrorHandlingService);
  currentUser = signal<CurrentUser | null>(null);
  regions = signal<Region[]>([]);
  regionsParam = signal('');
  selectedRegions = signal<Region[]>([]);
  selectedCountries = signal<string[]>([]);
  selectedCountry = signal('');
  selectedYear = signal(0);
  selectedCurrency = signal('');
  isMsalAuthenticated = signal(false);
  isAPIAuthenticated = signal(false);
  isFullyLoaded = false;
  notificationCount = signal(0);
  userRegionKeys!: string[];
  userCountrys!: string[];
  workflowStage = signal(0);
  isAppLocked = signal(false);

  private filterChanged = new Subject<boolean>();
  /** Only use this subject for triggering filter changes inside of component in the router-oulet */
  getFilterChangedSubject$ = this.filterChanged.asObservable();
  /** Generic trigger for applying filters for the route components to trigger an api call and page update
   * @param from  - `string | undefined` to track the caller
   */
  sendFilterChange(from?: string): void {
    if (from) {
      console.log('Filter Changed from:', from);
    }
    this.filterChanged.next(true);
  }

  currentAccess = computed(() => {
    const user = this.currentUser();
    if (!user) return AccessLevel.None;
    return user.role;
  });

  editableYear = computed(() => {
    const user = this.currentUser();
    if (!user) return null;
    for (const config of user.systemConfigurations) {
      if (config.isEditable) {
        return config.year;
      }
    }
    return null;
  });

  /** Checks if the field is visible for the user based on their role
   *
   * @param section `string` `'icp' or 'tdc'` the section to check the hidden field
   * @param field `string` field property name from data table properties
   * @returns `boolean' true if field is visible, false if field is hidden
   */
  checkFieldVisible(section: string, field: string): boolean {
    if (this.currentUser() !== null) {
      if (section === 'tdc') {
        let hiddenfieldExists = this.currentUser()?.tdcHiddenFields.find((x) => x == field);
        if (hiddenfieldExists) {
          return false;
        }
      } else if (section === 'icp') {
        let hiddenfieldExists = this.currentUser()?.icpHiddenFields.find((x) => x === field);
        if (hiddenfieldExists) {
          return false;
        }
      }
    }
    return true;
  }

  //#region API Calls
  getAuditLogs(): Observable<AuditLog[]> {
    return this._apiService.httpGet<AuditLog[]>('Users/AuditLogs');
  }
  //#endregion

  getCurrentUser(): void {
    const user = this.currentUser();
    if (!environment.production) {
      console.log(this.currentUser());
    }
    if (user !== null) return;

    const url = 'authorization/current';
    this._apiService.httpGet<CurrentUser>(url).subscribe({
      next: (user: CurrentUser) => {
        this.currentUser.update(() => user);
        //this.currentAccess.update(() => user.role);

        user.regions.forEach((region) => {
          region.hasCountries = region.countries.length > 0 ? true : false;
          if (region.hasCountries)
            region.countries = region.countries.sort((a, b) => a.localeCompare(b));
        });

        this.selectedCurrency.update(() => 'Local');
        this.regions.set(user.regions);
        this.updateYear(this.editableYear() ?? 0);

        this.changeRegions(user.regions);
        const getAllCountries = this.getDistinctCountriesInRegion(user.regions);

        if (getAllCountries.length > 0) {
          this.changeCountries(getAllCountries);
        } else {
          this.changeCountries([user.country]);
        }

        this.isAPIAuthenticated.update(() => true);
        if (user.role === AccessLevel.Global) {
          setTimeout(() => {
            this.getAuditLogs().subscribe((logs) => {
              if (logs) this.notificationCount.update(() => logs.length);
            });
          }, 4000);
        }
        if (!environment.production) {
          console.log('User:', this.currentUser());
        }
        this.isFullyLoaded = true;
      },
      error: (error) => {
        console.error('Error fetching current user', error.error ? error.error.error : error);
        this._errorHandlingService.handleError(error, 5000);
        const errorStatus = error.status ? error.status : 0;
        const unauthorizedErrors = [401, 403];
        this.isAPIAuthenticated.update(() => !unauthorizedErrors.includes(errorStatus));
        this.isFullyLoaded = true;
      },
      complete: () => {
        this.isFullyLoaded = true;
      },
    });
  }

  changeRegions(regions: Region[]) {
    // this.selectedRegions.update(() => regions);
    let regionKeys = regions.map((region) => region.regionKey);
    let concatenatedRegionKeys = '';
    regionKeys.forEach((regionKey, index) => {
      concatenatedRegionKeys += regionKey + (index < regionKeys.length - 1 ? ',' : '');
    });

    this.regionsParam.update(() => concatenatedRegionKeys);
    this.selectedRegions.update(() => regions);
  }

  changeCountries(countries: string[]) {
    let concatenatedCountries = '';
    countries.forEach((country, index) => {
      if (index === countries.length - 1) {
        concatenatedCountries += country;
      } else {
        concatenatedCountries += country + ',';
      }
    });

    this.selectedCountry.update(() => concatenatedCountries);
    this.selectedCountries.update(() => countries);
  }

  updateCurrency(currency: string): void {
    this.selectedCurrency.update(() => currency);
  }

  updateYear(year: number): void {
    this.selectedYear.update(() => year);
    const isEditableYear = year == this.editableYear();
    this.isAppLocked.update(() => !isEditableYear);
  }

  getDistinctCountriesInRegion(regions: Region[]): string[] {
    const countrySet = new Set<string>();

    regions.forEach((region) => {
      region.countries.forEach((country) => {
        countrySet.add(country);
      });
    });

    return Array.from(countrySet);
  }

  exportCompCommReport(): Observable<CompCommunicatorReport[]> {
    return this._apiService.httpGet<CompCommunicatorReport[]>('Reports/GetCompCommunicatorReport');
  }

  createIcpTdcYear(): Observable<any> {
    return this._apiService.httpPost<any>('Users/CreateIcpTdcYear', null);
  }

  // getEditableYear(): number | null {
  //   const user = this.currentUser();
  //   if(!user){
  //     return null;
  //   }
  //   for (const config of user.systemConfigurations) {
  //     if (config.isEditable) {
  //       return config.year;
  //     }
  //   }
  //   return null; // or any other default value
  // }
}
