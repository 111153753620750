import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MatSnackBarAction,
  MatSnackBarActions,
  MatSnackBarLabel,
  MAT_SNACK_BAR_DATA,
  MatSnackBarRef,
} from '@angular/material/snack-bar';
import { GenericSnackbar } from 'app/core/generic-model-types/snackbar.model';

@Component({
  selector: 'app-notification-snackbar',
  standalone: true,
  imports: [MatButtonModule, MatSnackBarLabel, MatSnackBarActions, MatSnackBarAction, CommonModule],
  templateUrl: './notification-snackbar.component.html',
  styleUrls: ['./notification-snackbar.component.scss'],
})
export class NotificationSnackbarComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public message: GenericSnackbar,
    private snackBarRef: MatSnackBarRef<NotificationSnackbarComponent>,
  ) {}

  closeSnackbar(): void {
    this.snackBarRef.dismiss();
  }
}
