<div
  [ngClass]="{
    success: message.type === 0,
    error: message.type === 1,
    info: message.type === 2,
  }"
>
  <span>{{ message.message }}</span>
  @if (message.showconfirmButton) {
    <button mat-button (click)="closeSnackbar()">OK</button>
  }
</div>
