export enum ReportType {
  None = 0,
  TDC = 10,
  ICP = 20,
  TDC_ICP = 30,
  EMSP = 40,
  All = 100,
}

export enum AccessLevel {
  Global = 100,
  CompensationCommittee = 90,
  RMD = 65,
  SrMD = 55,
  MD = 45,
  SolutionMD = 35,
  IndustryMD = 25,
  CountryMarketLeader = 15,
  GlobalIndustryLeader = 5,
  None = 0,
}

export enum LeadershipRoles {
  SolutionLeader,
  IndustryLeader,
  RegionalMD,
  MarketLeader,
  GSAMLead,
  SeniorOperationsVP,
}

export enum PartTimeAgreement {
  Yes,
  No,
}

export enum RiskEvaluation {
  GoldStar,
  NeedsImprovement,
  None,
}

export enum AuditAction {
  ADD,
  UPDATE,
  DELETE,
  COMP_CREATED,
  COMP_SENT,
  COMP_UPDATED,
  COMP_APPROVED,
  COMP_REJECTED,
}

export enum Currency {
  USD = 'USD',
  LOCAL = 'Local',
}

export enum FilterInputType {
  StringInput,
  NumberInput,
  Autocomplete,
  Select,
  Multiselect,
}

export enum BudgetToActualsType {
  None,
  NA,
  Canada,
  International,
}

export enum WorkflowSection {
  TDC,
  ICP,
  ProgressPayment,
  EMSP,
}

export enum WorkflowStatus {
  Draft,
  Submitted = 5,
  Reviewed = 10,
  Entity2Reviewed = 12,
  Entity3Reviewed = 13,
  Pending = 15, //Entity1Level2Review
  Approved = 20,
  Rejected = 25,
  Closed = 30,
}

export enum WorkflowTaskStatus {
  Draft,
  Approved,
  Rejected,
}

export enum ApproverType {
  None,
  Admin,
  CompCommity,
  CML,
  RMD,
  FinalApprover,
}

export enum TaskType {
  Entity1Review, //Submit - Admin/CompCommittee
  Entity2Review,
  Entity3Review,
  Entity1Level2Review,
  FinalApprove, //Final Approver
  Close, // To Finalize / Close the Workflow
}
